<script setup>
 // Composables
 import { getPage, handleBuilderUpdates } from '@/composables/builder';

 const page = await getPage();

 handleBuilderUpdates(page);

 const postMeta = useState('postMeta', () => page.value?.postMeta);
 const user = useState('user', () => false);
 const cart = useState('cart', () => false);

 //console.log('user client side else where in the app!', user.value);
 //console.log('cart client side else where in the app!', cart.value);

</script>

<template>
 <div>
  <div
   v-if="page && page.model && page.content && page.content.id"
   class="page-wrapper"
   :data-model="page.model"
   :key="page.content.id"
  >
   <GlobalBreadcrumbs v-if="page" :page="page" />

   <TemplatesBlog v-if="page.model == 'blog'" :page="page" />
   <TemplatesTours v-else-if="page.model == 'tours'" :page="page" />
   <TemplatesProgramsEvents v-else-if="page.model == 'programs-events'" :page="page" />
   <TemplatesExhibitions v-else-if="page.model == 'exhibitions'" :page="page" />
   <TemplatesBuildings v-else-if="page.model == 'buildings'" :page="page" />
   <TemplatesArchitectureEncyclopediaArticles
    v-else-if="page?.model == 'architecture-encyclopedia-articles'"
    :page="page"
   />
   <TemplatesResources v-else-if="page.model == 'resources'" :page="page" />
   <TemplatesStories v-else-if="page.model == 'stories'" :page="page" />
   <TemplatesTeamMembers v-else-if="page.model == 'team-members'" :page="page" />
   <TemplatesPage v-else :page="page" />

   <GlobalFooter />
  </div>
 </div>
</template>

<style lang="scss">
 .page-wrapper {
  margin-top: 55px;
  width: 100vw;

  @media (min-width: $mobile) {
   margin-top: 88px;
  }

  [class*='builder-pixel'] {
   position: absolute;
  }
 }
</style>
